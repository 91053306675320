import React from "react"
import Layout from "@components/kaigai-fx/layout";
import 'tw-elements';

// 関数呼び出し
import { TermComponent } from "@components/kaigai-fx/term-component.js";

const TermPageN = () => {
  // 設定項目 ================================================================================================
  const myLine = 'な行'
  const myID = 'na_'
  const pathName = 'term-na';
  const termItem = [ // 改行するときは<br>を追加
  {
    Title:'仲値',
    Content:'銀行などの金融機関が顧客との外国為替取引で基準とするレートのこと。<br>午前9時55分の外国為替市場のレートを参考に決定します。<br>日本円を外貨に両替したり、外貨を日本円に戻す際のレートの開きに対して、中間に位置するのが仲値です。'
  },
  {
    Title:'成行注文',
    Content:'売買を行うときに、値段を自分で設定しない注文方法です。<br>成行注文は、取引時間中であれば即座に注文が約定するため、指値注文より優先して売買が成立します。'
  },
  {
    Title:'難平（ナンピン）',
    Content:'購入した株式などの有価証券が予想に反して下落した際に、平均取得単価（平均コスト）を下げるためにさらに追加で購入する取引手法です。<br>ナンピンの計算方法は、「平均取得単価=取引をした価格÷合計Lot（ロット）数」です。<br>ナン(難)=損を平均することからナンピン(難平)といいます。'
  },
  {
    Title:'値洗い',
    Content:'未決済の先物や信用取引などの保有する資産価値に対し、元々の値段を時価（清算値段）で再評価して、評価損益を証拠金などに反映することを指します。<br>値洗いにより証拠金の不足が生じた場合、顧客には追加証拠金の差し入れが求められるます。'
  }
  ]
  // =================================================================================================================================
  // term-component.jsにて詳細設定
  let termPageContent = TermComponent( myLine, myID, termItem, pathName ); 
  
  return (
  <>
    <Layout>
      {termPageContent}
    </Layout>
  </>
  )
}
export default TermPageN
